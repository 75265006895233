<template>
  <div id="invoice-page">
    <vx-card title="تقرير  مندوب" title-color="primary">
      <vs-divider position="left" textClass="text-size" color="primary">
        متغيرات التقرير
      </vs-divider>
      <div class="vx-row">
        <div class="vx-col w-1/4">
          <label class="vs-input--label"> إسم المندوب</label>
          <v-select
            name="sku"
            class="w-full"
            data-vv-as="sku"
            data-vv-scope="order-items"
            label="name"
            :options="reps"
            v-model="rep_report.rep"
            :tabindex="4"
            :filterable="true"
          ></v-select>
        </div>
        <div class="vx-col w-1/4">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="rep_report.from"
            v-validate="'required'"
            label="مـــن"
          />
        </div>
        <div class="vx-col w-1/4">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="rep_report.to"
            v-validate="'required'"
            label="الى"
          />
        </div>
        <div class="vx-col w-1/4">
          <vs-button
            class="mb-base mr-3 mt-5"
            icon-pack="feather"
            @click="getReport"
            >إستخراج تقرير
          </vs-button>
        </div>
      </div>
    </vx-card>
    <div class="flex items-center justify-end mt-4" v-if="report_data.Orders.length">
      <vs-button
        class="mb-base mr-3"
        icon-pack="feather"
        icon="icon icon-file"
        @click="printInvoice"
        >طباعة</vs-button
      >
    </div>
    <vx-card v-if="report_data.Orders.length" class="mt-4" id="invoice-container">
      <reps-report
        :data="report_data"
        :rep='rep_report'
      ></reps-report>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import repsReport from "./components/reps.vue";
export default {
  components: {
    vSelect,
    repsReport,
  },
  data() {
    return {
      rep_report: {
        rep: [],
        from: "",
        to: "",
      },
      report_data: {
        Orders:[]
      },
    };
  },
  computed: {
    reps() {
      return this.$store.state.reps.reps;
    },
  },
  methods: {
    ...mapActions("reps", ["fetchReps"]),
    ...mapActions("reports", ["fetchRepReport"]),
    printInvoice() {
      window.print();
    },
    getReport() {
      let bodyFormdata = new FormData();
      bodyFormdata.set(`delivery_id`, this.rep_report.rep.id);
      bodyFormdata.set("from_date", this.rep_report.from);
      bodyFormdata.set("to_date", this.rep_report.to);
       this.fetchRepReport(bodyFormdata)
        .then((response) => {
          this.report_data = response;
          if (this.report_data.Orders.length) {
            this.$vs.notify({
              title: "تم بنجاح",
              text: "تم إستخراج التقرير بنجاح",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "عملية غير ناجحة",
              text: "إما إنه لا يوجد بيانات لهذا العميل أو إنه لا توجد بيانات للفترة الزمنية التي تم تحديدها.",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  created() {
    this.fetchReps();
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
  },
};
</script>

<style>
</style>
